export default {
  comboOrderList1: 'Purchase history',
  comboOrderList2: 'All',
  comboOrderList3: 'To be paid',
  comboOrderList4: 'Canceled',
  comboOrderList5: 'Payment successful',
  comboOrderList6: 'Payment failed',
  comboOrderList7: 'Creation time',
  comboOrderList8: 'Order number',
  comboOrderList9: 'Payment account number',
  comboOrderList10: 'Combo',
  comboOrderList11: 'Amount payable($)',
  comboOrderList12: 'Actual amount paid($)',
  comboOrderList13: 'Discount amount($)',
  comboOrderList14: 'Payment status',
  comboOrderList15: 'Order generation time',
  comboOrderList16: 'Payment channel',
  comboOrderList17: 'Pay',
};