export default {
  account: {
    components1: '账号列表',
    components2: '账号/昵称搜索',
    components3: '条消息',
    components4: '加载中...',
    components5: '当前不支持使用该功能，请升级购买套餐',
  },
  fans: {
    components1: '对话',
    components2: '账号/昵称/备注搜索',
    components3: '条消息',
    components4: '加载中...',
    components5: '粉丝信息',
    components6: '粉丝ID：',
    components7: '昵称备注：',
    components8: '请输入',
    components9: '备注',
    components10: '请输入备注，不超过200字符…',
    components11: '最多选择5个标签',
    components12: '标签',
    components13: '请选择标签',
    components14: '跟进记录',
    components15: '请输入跟进记录',
    components16: '确认',
    components17: '取消',
    components18: '添加跟进记录',
    components19: '修改成功',
    components20: '修改失败',
    components21: '添加成功',
    components22: '添加失败',
    components23: '删除成功',
    components24: '删除失败',
    components25: '确认删除？',
  },
  msg: {
    components1: '加载中...',
    components2: '查看上下文',
    components3: '[图片]',
    components4: '[视频]',
    components5: '[音频]',
    components6: '[文件]',
    components7: '[名片]',
    components8: '[其他]',
  },
  translateSetting: {
    components1: '翻译设置',
    components2: '聊天消息自动翻译',
    components3: '目标语言',
    components4: '选择语言',
    components5: '翻译路线',
    components6: '选择翻译路线',
    components7: '源语言',
    components8: '选择源语言',
    components9: '保存',
    components10: '重置',
    components11: '保存成功',
    components12: '保存失败',
    components13: '系统检测',
  },
};