export default {
    thinking: '正在思考',
    inputMessage:'输入消息',
    confirmReset: '是否确认重置',
    aiTrain: 'AI智能训练师',
    prompt: '提示词',
    promptTip1: '请选定适合客户端使用的智能体。选定后，客户端将照当前训练内容进行回复。',
    promptTip2: '发布完成后，若智能体进行进一步训练并调整内容，该调整将不会上传至客户端，需重新进行发布。',
    promptTip3: '每次有新的发布，先前的发布将自动失效。',
    selectPrompt: '请选择发布智能体',
    getDataFail: '获取数据失败,请稍后再试',
    aiName: '名称',
    inputName: '请输入名称',
    aiIndex: '序号',
    createTime:'生成时间',
    aiReply: 'AI回复智能体',
    clientTip: '客户端将依据配置的智能体模型内容执行智能回复。',
    aiRelease: '智能体发布',
    aiModelList: '智能模型体列表',
    createAiAgent: '新建智能体',
    strip: '条',
    modelTraining: '模型训练',
    modelTestChat: '模型测试聊天',
    aiIntelligentReply: "AI智能回复",
    simulationCustomer: "模拟客户",
    configurationParameter: "配置参数",
    aiChannel: 'AI通道',
    pleaseSelectTheAiChannel: "请选择AI通道",
    isDeleteAiAgent: "是否确认删除智能体",
    deleteAiAgent: "删除智能体",
    editAiAgentName: '修改智能体名称',
    failureToModify: "修改失败",
    rulesMsg1: '当启用智能体时AI智能回复将根据启用的智能体模型的内容来生成相应的回复语句。',
    rulesMsg2: '智能体训练完后后可在发布模块中进行发布。',
    rulesMsg3: '客户端需启用AI回复功能，方能使用此功能。',
    rulesMsg4: '客户端AI回复的内容参数将根据智能体模型内容以及客户端设定的语调、语气和接收消息的数量来确定。',
    rulesMsg5: '当消息遇到触发相关关键词时，AI智能将不会自动进行回复。',
    failureToCreateAnIntelligentBody: "创建智能体失败",
    createASmartPartySuccess: "创建智能体成功",
    deleteTheIntelligentBodyFailure: "删除智能体失败",
    pleaseEnterTheContentYouWantToSend: "请输入要发送的内容",
    sendError: "发送失败, 请稍后再试",
    resetError: "重置失败, 请稍后再试",
    clearRecord: "已清空记录",
    modelName: "模型名称",
    return: "返回",
    pleaseEnterTheModelName:"请输入模型名称",
    createRelase: "创建发布",
    releaseStatus: "发布状态",
    publishing: "发布中",
    invalid: "已失效",
    releasePromptCount: "发布时提示词数量",
    whetherToConfirmTheCancellation: "是否确认撤销",
    unpublish: "撤销发布",
    publishAPromptContent: "发布提示词内容",
    createReleaseError: '创建发布失败, 请稍后刷新重试',
    cancelReleaseError: '撤销发布失败, 请稍后刷新重试',
    aiAgentName: "智能体名称",
    releaseTime: "发布时间",
    getDataError: "获取数据失败, 请稍后刷新重试",
}