export default {
    thinking: 'Thinking',
    inputMessage: 'Input message',
    confirmReset: 'Confirm reset?',
    aiTrain: 'AI Intelligent Trainer',
    prompt: 'Prompt',
    promptTip1: 'Please select an agent suitable for client use. Once selected, the client will respond based on the current training content.',
    promptTip2: 'After publishing, if the agent undergoes further training and adjustments, these changes will not be uploaded to the client. Re-publishing is required.',
    promptTip3: 'Each new publication automatically invalidates the previous one.',
    selectPrompt: 'Please select an agent to publish',
    getDataFail: 'Failed to retrieve data, please try again later',
    aiName: 'Name',
    inputName: 'Please enter a name',
    aiIndex: 'Index',
    createTime: 'Creation time',
    aiReply: 'AI Reply Agent',
    clientTip: 'The client will perform intelligent replies based on the configured agent model content.',
    aiRelease: 'Agent Publishing',
    aiModelList: 'AI Model List',
    createAiAgent: 'Create New Agent',
    strip: 'Items',
    modelTraining: 'Model Training',
    modelTestChat: 'Model Test Chat',
    aiIntelligentReply: 'AI Intelligent Reply',
    simulationCustomer: 'Simulated Customer',
    configurationParameter: 'Configuration Parameters',
    aiChannel: 'AI Channel',
    pleaseSelectTheAiChannel: 'Please select the AI channel',
    isDeleteAiAgent: 'Confirm deletion of the agent?',
    deleteAiAgent: 'Delete Agent',
    editAiAgentName: 'Edit Agent Name',
    failureToModify: 'Failed to modify',
    rulesMsg1: 'When the agent is enabled, AI intelligent replies will generate responses based on the content of the enabled agent model.',
    rulesMsg2: 'After training the agent, it can be published in the publishing module.',
    rulesMsg3: 'The client must enable the AI reply feature to use this functionality.',
    rulesMsg4: 'The content parameters of client AI replies are determined based on the agent model, tone, mood, and the number of received messages set by the client.',
    rulesMsg5: 'When a message contains trigger keywords, AI intelligence will not automatically respond.',
    failureToCreateAnIntelligentBody: 'Failed to create the agent',
    createASmartPartySuccess: 'Agent created successfully',
    deleteTheIntelligentBodyFailure: 'Failed to delete the agent',
    pleaseEnterTheContentYouWantToSend: 'Please enter the content you want to send',
    sendError: 'Failed to send, please try again later',
    resetError: 'Failed to reset, please try again later',
    clearRecord: 'Records cleared',
    modelName: 'Model Name',
    return: 'Return',
    pleaseEnterTheModelName: 'Please enter the model name',
    createRelase: 'Create Publication',
    releaseStatus: 'Publication Status',
    publishing: 'Publishing',
    invalid: 'Invalidated',
    releasePromptCount: 'Number of prompts at the time of publication',
    whetherToConfirmTheCancellation: 'Confirm cancellation?',
    unpublish: 'Unpublish',
    publishAPromptContent: 'Publish Prompt Content',
    createReleaseError: 'Failed to create publication, please refresh and try again',
    cancelReleaseError: 'Failed to cancel publication, please refresh and try again',
    aiAgentName: 'Agent Name',
    releaseTime: 'Release Time',
    getDataError: 'Failed to retrieve data, please refresh and try again',
}