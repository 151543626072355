export default {
  comboOrderList1: '购买记录',
  comboOrderList2: '全部',
  comboOrderList3: '待支付',
  comboOrderList4: '已取消',
  comboOrderList5: '支付成功',
  comboOrderList6: '支付失败',
  comboOrderList7: '创建时间',
  comboOrderList8: '订单号',
  comboOrderList9: '收款账号',
  comboOrderList10: '套餐',
  comboOrderList11: '应付金额($)',
  comboOrderList12: '实付金额($)',
  comboOrderList13: '折扣金额($)',
  comboOrderList14: '支付状态',
  comboOrderList15: '订单生成时间',
  comboOrderList16: '支付渠道',
  comboOrderList17: '支付',
};