export default {
  menu1: '邀请码管理',
  menu2: '工单管理',
  menu3: '粉丝活链',
  menu4: '粉丝标签',
  menu5: '粉丝数据',
  menu6: '购买记录',
  menu7: '话术管理',
  menu8: '话术素材库',
  menu9: '关键词回复',
  menu10: '欢迎语回复',
  menu11: '推广返佣',
  menu12: '收益统计',
  menu13: '高额返利',
  menu14: '推广明细',
  menu15: '聊天监控',
  menu16: '内容管理',
  menu18: '敏感词监控',
  menu19: '敏感词监控详情',
  menu20: '敏感行为监控',
  menu21: '编辑消息详情',
  ai_menu1: 'AI智能',
  ai_menu2: 'AI回复智能体',
  ai_menu3: '发布管理'
};

