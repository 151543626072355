export default {
    components1:"Return",
    components2:"This account is created in the background, please",
    components3:"Contact Us",
    components4:"change password",
    components5: 'Confirm',
    components6: 'Cancel',
    components7: '1.What is invitation code?',
    components8: 'The supervisor creates a login code for logging into the client on the management side, which can be provided to colleagues or partners for use.',
    components9: '2.How to create an invitation code (sub-account)?',
    components10: 'Click on the "New Sub-Account" option, and then configure corresponding functional permissions for the invitation code (sub-Account), such as social platforms, ports, notes, etc. ',
    components11: 'I already understand',
    components12: 'Next',
    components13: 'Close prompt',
    components14: 'Does the shutdown boot not pop up next time?',
    components15: 'Close failed'
}