export default {
  chatIndex1: 'Global chat history search',
  chatIndex2: 'Please enter keywords',
  chatIndex3: 'Reset',
  chatIndex4: 'Save chat history for the last 60 days',
  chatIndex5: 'Global search chat history',
  chatIndex6: 'Invitation code (sub-account):',
  chatIndex7: 'all',
  chatIndex8: 'Open archive',
  chatIndex9: 'Close archive',
  chatIndex10: 'Social platforms:',
  chatIndex11: 'Find',
  chatIndex12: 'Reset',
  chatIndex13: 'Chat history',
  chatIndex14: 'back',
};
