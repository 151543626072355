export default {
  behavior1: 'Sensitive behavior monitoring',
  behavior2: 'Batch delete',
  behavior3: 'All',
  behavior4: 'Invitation code',
  behavior5: 'social platform',
  behavior6: 'social account',
  behavior7: 'Please enter',
  behavior8: 'Please choose',
  behavior9: 'Delete message',
  behavior10: 'Edit message',
  behavior11: 'Delete the dialogue',
  behavior12: 'Operation type',
  behavior13: 'Operation account',
  behavior14: 'fan account',
  behavior15: 'Delete content',
  behavior16: 'Edit times',
  behavior17: 'Operation',
  behavior18: 'Operation time',
  behavior19: 'Operation type',
  behavior20: 'Send message',
  behavior21: 'Receive message',
  behavior22: '[Picture]',
  behavior23: '[Video]',
  behavior24: '[Audio]',
  behavior25: '[File]',
  behavior26: '[Business Card]',
  behavior27: '[Other]',
  behavior28: 'Edit record',
  behavior29: 'Edit before content',
  behavior30: 'After the editor',
  behavior31: 'Edit time',
  behavior32: 'No. {num} times',
  behavior33: 'Please select sensitive behavior to delete',
  behavior34: 'Tip',
  behavior35: 'Confirm that delete?',
  behavior36: 'Confirm',
  behavior37: 'Cancel',
  behavior38: 'Delete successful',
  behavior39: 'Delete failed',
};
