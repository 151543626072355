/**
 * 国际化 utils
 * @author
 */
import { VueMessageType } from 'vue-i18n'
import * as zh_CN from '../locales/zh-CN/index'
import * as en from '../locales/en-US/index'

// window.localStorage 存储key
export const localeKey = 'locale'

// 默认语言
export const defaultLang = (() => {
    let lang = navigator.language
    if (["zh-CN", "zh", "en"].includes(lang)) {
        if (lang == 'zh') lang = 'zh-CN'
        return lang
    }
    return 'zh-CN'
})();

// export const defaultLang = "en";

/**
 * 验证语言命名规则 zh
 * @returns boolen
 * @author
 */
export const localeNameExp = (lang: string): boolean => {
    const localeExp = new RegExp('^([a-z]{2})-?([A-Z]{2})?$')
    return localeExp.test(lang)
}

/**
 * 设置 html lang 属性值
 * @param lang 语言的 key
 * @author
 */
export const setHtmlLang = (lang: string): void => {
    /**
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
    document.querySelector('html')?.setAttribute('lang', lang)
    window.localStorage.setItem(localeKey, lang)
}

/**
 * 获取当前选择的语言
 * 获取的浏览器语言默认项目中有可能不支持，所以在config/i18n.ts中要加以判断
 * @returns string
 * @author
 */
export const getLocale = (): string => {
    let localStorage = window.localStorage.getItem(localeKey)
    const lang = localStorage != null ? localStorage : defaultLang
    const isNavigatorLanguageValid =
    typeof navigator !== 'undefined' && typeof navigator.language === 'string'
    const browserLang = isNavigatorLanguageValid
        ? navigator.language.split('-').join('-')
        : ''
    return lang || browserLang || defaultLang
}

/**
 * 切换语言
 * @param lang 语言的 key
 * @param realReload 是否刷新页面，默认刷新
 * @author
 */
export const setLocale = (
    lang: string,
    realReload = true,
    callback: () => void
): void => {
    if (lang !== undefined && !localeNameExp(lang)) {
    // for reset when lang === undefined
        throw new Error('setLocale lang format error')
    }
    if (getLocale() !== lang) {
        if (typeof window.localStorage !== 'undefined') {
            window.localStorage.setItem(localeKey, lang || '')
        }

        if (realReload) {
            window.location.reload()
        } else {
            setHtmlLang(lang)

            if (typeof callback === 'function') {
                callback()
            }
        }
    }
}

/**
 * 自动导入 框架自定义语言
 * @author
 */
export function importAllLocales(): {} {
    let modules: {} = {}
    modules = {
      'zh-CN': {
        ...zh_CN.default.Menu,
        ...zh_CN.default.Common,
        ...zh_CN.default.Components,
        ...zh_CN.default.Protocol,
        ...zh_CN.default.Login,
        ...zh_CN.default.Register,
        ...zh_CN.default.Reset,
        ...zh_CN.default.WorkOrderList,
        ...zh_CN.default.WorkOrderDetail,
        ...zh_CN.default.WorkOrderFansDetail,
        ...zh_CN.default.WorkOrderAccountLog,
        ...zh_CN.default.WorkOrderComponents,
        ...zh_CN.default.FansMerchant,
        ...zh_CN.default.FansLinkList,
        ...zh_CN.default.FansLinkComponents,
        ...zh_CN.default.FansLabelList,
        ...zh_CN.default.ComboOrderList,
        ...zh_CN.default.FansLabelComponents,
        ...zh_CN.default.CodeList,
        ...zh_CN.default.CodeDetail,
        ...zh_CN.default.CodeFriendsDetail,
        ...zh_CN.default.CodeComponents,
        ...zh_CN.default.LayoutComponents,
        ...zh_CN.default.LayoutIndex,
        ...zh_CN.default.CustomerService,
        ...zh_CN.default.FirstLogin,
        ...zh_CN.default.AccountLog,
        ...zh_CN.default.PromoList,
        ...zh_CN.default.PromoDetail,
        ...zh_CN.default.PromoStatistics,
        ...zh_CN.default.ChatIndex,
        ...zh_CN.default.ChatComponents,
        ...zh_CN.default.Sensitive,
        ...zh_CN.default.Talk,
        ...zh_CN.default.Behavior,
        ...zh_CN.default.Ai,
      },
      en: {
        ...en.default.Menu,
        ...en.default.Common,
        ...en.default.Components,
        ...en.default.Protocol,
        ...en.default.Login,
        ...en.default.Register,
        ...en.default.Reset,
        ...en.default.WorkOrderList,
        ...en.default.WorkOrderDetail,
        ...en.default.WorkOrderFansDetail,
        ...en.default.WorkOrderAccountLog,
        ...en.default.WorkOrderComponents,
        ...en.default.FansMerchant,
        ...en.default.FansLinkList,
        ...en.default.FansLinkComponents,
        ...en.default.FansLabelList,
        ...en.default.ComboOrderList,
        ...en.default.FansLabelComponents,
        ...en.default.CodeList,
        ...en.default.CodeDetail,
        ...en.default.CodeFriendsDetail,
        ...en.default.CodeComponents,
        ...en.default.LayoutIndex,
        ...en.default.LayoutComponents,
        ...en.default.CustomerService,
        ...en.default.FirstLogin,
        ...en.default.AccountLog,
        ...en.default.PromoList,
        ...en.default.PromoDetail,
        ...en.default.PromoStatistics,
        ...en.default.ChatIndex,
        ...en.default.ChatComponents,
        ...en.default.Sensitive,
        ...en.default.Talk,
        ...en.default.Behavior,
        ...en.default.Ai,
      },
    };
    return modules
}
