import internal from 'stream'

export enum IMenubarStatus {
    PCE, // 电脑展开
    PCN, // 电脑合并
    PHE, // 手机展开
    PHN // 手机合并
}
export interface ISetting {
    theme: number
    color: {
        primary: string
    }
    mode: 'horizontal' | 'vertical' // 导航模式
}
export interface IMenubar {
    status: IMenubarStatus
    menuList: Array<IMenubarList>
    isPhone: boolean
}
export interface IUserInfo {
  id: number;
  name: string;
  username: string;
  expireTime: string;
  usedPort: number;
  mode: number;
  multiAccount: number;
  productId: number;
  userLevel: number;
  traffic: number;
  nickName: string;
  isFree: string;
  resetPwd:number;
  invitationHint:number
}
export interface IChannelInfo {
  name: string;
  name_en: string;
  WebLogo: string;
  LongLogo: string;
  UploadZip: string;
  ClientLogo: string;
  InstallLogo: string;
  UnInstallLogo: string;
  official_link: string;
  home_link: string;
  social_link: string;
  manual_link: string;
  serve_link: string;
  ChannelId: number;
}
export interface IPlatformAuth {
    list: Array<IPlatformAuthList>,
}
export interface IPlatformAuthList{
    id: number,
    socialAppType:string
}
export interface IStatus {
    isLoading: boolean
    ACCESS_TOKEN: string
}
export interface ILayout {
    // 左侧导航栏
    menubar: IMenubar
    // 用户信息
    userInfo: IUserInfo
    // 渠道信息
    channelInfo: IChannelInfo
    setting: ISetting
    status:IStatus
    platformAuth:IPlatformAuth,

}
export interface IMenubarList {
  parentId?: number | string;
  id?: number | string;
  name: string;
  path: string;
  redirect?: string | { name: string };
  meta: {
    icon?: string;
    activeIcon?: string;
    title: string;
    permission?: string[];
    activeMenu?: string; // 路由设置了该属性，则会高亮相对应的侧边栏
    noCache?: boolean; // 页面是否不缓存
    hidden?: boolean; // 是否隐藏路由
    alwaysShow?: boolean; // 当子路由只有一个的时候是否显示当前路由
    langKey?: string; // 菜单多语言key
    hotIcon?: string; // hot标签图标
    hotGif?: string; // hot标签图标
    channelId?: number[]; //  那些渠道可以展示
  };
  component: (() => Promise<typeof import('*.vue')>) | string;
  children?: Array<IMenubarList>;
}

