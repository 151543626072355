export default {
  sensitive1: '敏感词监控',
  sensitive2: '编辑',
  sensitive3: '详情',
  sensitive4: '删除',
  sensitive5: '监控状态',
  sensitive6: '敏感词',
  sensitive7: '触发次数',
  sensitive8: '是否发送',
  sensitive9: '修改成功',
  sensitive10: '修改失败',
  sensitive11: '禁止发送',
  sensitive12: '允许发送',
  sensitive13: '批量删除',
  sensitive14: '添加敏感词',
  sensitive15: '删除敏感词',
  sensitive16: '提示',
  sensitive17: '确认要删除？',
  sensitive18: '确认',
  sensitive19: '取消',
  sensitive20: '请选择要删除的敏感词',
  sensitive21: '全部',
  sensitive22: '启动',
  sensitive23: '不启用',
  sensitive24: '敏感词',
  sensitive25: '添加敏感词',
  sensitive26: '编辑敏感词',
  sensitive27: '保存成功',
  sensitive28: '保存失败',
  sensitive29: '敏感词监控功能说明',
  sensitive30:
    '1.启用敏感词监控功能后，社交账号聊天内容触发敏感词将自动记录于后台。',
  sensitive31:
    '2.禁止发送的敏感词消息无法发出；允许发送的敏感词消息可发出，但后台记录。',
  sensitive32: '请选择要删除的敏感词详情',
  sensitive33: '跳转至聊天记录',
  sensitive34:
    '后台实时监控客户端发送内容是否包含敏感词，并可以设置禁止发送敏感词',
  sensitive35: '敏感词监控详情',
  sensitive36: '邀请码',
  sensitive37: '社交平台',
  sensitive38: '发送时间',
  sensitive39: '邀请码',
  sensitive40: '社交平台',
  sensitive41: '社交账号',
  sensitive42: '接收对象',
  sensitive43: '发送内容',
  sensitive44: '跳转到聊天记录',
  sensitive45: '3.直接发送或翻译后的内容均可触发敏感词检测。',
};
