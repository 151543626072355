import router from '/@/router'
import { configure, start, done } from 'nprogress'
import { RouteRecordRaw } from 'vue-router'
import { decode, encode, useLocal } from '/@/utils/tools'
import { useLayoutStore } from '/@/store/modules/layout'
import { i18n } from '/@/config/i18n';
import { isString } from '/@/utils/is'
const { t } = i18n.global;

configure({ showSpinner: false })

const loginRoutePath = '/Login'
const defaultRoutePath = '/'
const whiteList = [
  '/fansMerchant/verify',
  '/fansMerchant/w_detail',
  '/Register',
  '/Reset',
  '/PrivacyPolicy',
];

router.beforeEach(async(to, from) => {
    start()
    const { getStatus, getMenubar, setToken, logout, concatAllowRoutes } = useLayoutStore()
    // 修改页面title
    const reg = new RegExp(/^(.+)(\s\|\s.+)$/)
    const appTitle = import.meta.env.VITE_APP_TITLE
    document.title = !to.meta.title
        ? appTitle
        : appTitle.match(reg)
            ? appTitle.replace(reg, `${to.meta.title}$2`)
            : isString(to.meta.langKey)?(t(to.meta.langKey)) +'|'+ t(appTitle):t(appTitle)
    // 判断当前是否在登陆页面
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
        done()
        return
    }
    if (to.path.toLocaleLowerCase() === loginRoutePath.toLocaleLowerCase()) {
        done()
        if (getStatus.ACCESS_TOKEN) {
            return typeof to.query.from === 'string' ? decode(to.query.from) : defaultRoutePath
        }
        return
    }
    // 判断是否登录
    if (!getStatus.ACCESS_TOKEN) {
        return loginRoutePath + ''
    }
    // 前端检查token是否失效
    useLocal('token')
        .then(d => setToken(d.ACCESS_TOKEN))
        .catch(() => logout())


    // 判断是否还没添加过路由
    if (getMenubar.menuList.length === 0) {
        for (let i = 0; i < getMenubar.menuList.length; i++) {
            router.addRoute(getMenubar.menuList[i] as RouteRecordRaw)
        }
        concatAllowRoutes()
        return to.fullPath
    }

})

router.afterEach(() => {
    done()
})
