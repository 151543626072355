export default {
  chatIndex1: '全局聊天记录搜索',
  chatIndex2: '请输入关键词',
  chatIndex3: '重置',
  chatIndex4: '保存最近60天聊天记录',
  chatIndex5: '全局搜索聊天记录',
  chatIndex6: '邀请码(子账号)：',
  chatIndex7: '全部',
  chatIndex8: '开启存档',
  chatIndex9: '关闭存档',
  chatIndex10: '社交平台：',
  chatIndex11: '查找',
  chatIndex12: '重置',
  chatIndex13: '聊天记录',
  chatIndex14: '返回',
};
