import { useLayoutStore } from "/@/store/modules/layout";
import axios from "axios";
import { ElLoading, ElNotification } from "element-plus";
import { AxiosRequestConfig } from "axios";
import { getLocale } from '/@/utils/i18n'
import i18n from '/@/config/i18n'


let paramConfig: any = {};

// let loading: { close(): void };
// 创建 axios 实例
const { t } = i18n.global;

const request = axios.create({
  // API 请求的默认前缀
  baseURL: import.meta.env.VITE_API_URL as string | undefined,
  timeout: 60000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error:{message:string, response:any}) => {
    const { reomveStorage } = useLayoutStore()
    // debugger
    // loading.close()
    let errMsg = error.message
    if(error.response.status === 401){
        errMsg = t('common24')
        const isShare = window.location.hash.search('fansMerchant/w_detail') > -1
        reomveStorage(!isShare)
        if (isShare) {
          const paramsString = window.location.hash.split('?')[1]; // 获取 ? 后面的部分
          const urlParams = new URLSearchParams(paramsString);
          const id = urlParams.get('id'); // 获取 id 的值
          window.location.href = window.location.origin + `/#/fansMerchant/verify?id=${id}`
        }
    }
    ElNotification({
        title: t('common33'),
        message: errMsg,
        type: 'error'
    })
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    const { getStatus } = useLayoutStore()
    const isToken = (config.headers || {}).isToken === false
    // loading = ElLoading.service({
    //     lock: true,
    //     text: "",
    //     spinner: 'el-icon-loading',
    //     background: 'rgba(225, 255, 255, 0.4)'
    // })
    const token = getStatus.ACCESS_TOKEN
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token && !isToken) {
        config.headers['Authorization'] = 'Bearer '+token
    }

    config.headers['Language'] = getLocale()
    paramConfig = config
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  const { data } = response;
  // loading.close();
  if (paramConfig.url == "/base/comm/getWorkOrderAccountFansExcel") {
    if (response.headers["content-type"] == "application/force-download") {
      return {
        data: data,
        code: 1000,
      };
    } else {
      return {
        data: data,
        code: 1046,
      };
    }
  } else if (response.headers["content-type"] == "application/force-download") {
    return response
  } else {
    if (data.code !== 1000) {
      codeErrorHandler(data);
      return Promise.reject(new Error(data.msg || "Error"));
    }

    return response.data;
  }
}, errorHandler);

// code异常处理
const codeErrorHandler = async (data) => {
  const { getStatus, logout } = useLayoutStore();
  let title = t('common33')
  if (data.code === 401) {
    title = t('common25')
    if (getStatus.ACCESS_TOKEN) {
      logout();
    }
  }
  ElNotification({
    title,
    message: data.msg,
    type: "error",
  });
};

export interface Response<T = any> {
  code: number;
  data: T;
  msg: string;
  time: number;
}

const req = async <T = any>(config: AxiosRequestConfig) => {
  const res = await request(config);
  return res as T;
};

export default req;
