export default {
  protocol1:
    '欢迎您使用我们（即%channelName%APP服务提供者，简称“我们”）的产品和服务，本服务协议适用于%channelName%APP服务提供者以网站、软件客户端以及随技术发展出现的新形态向您提供的各项产品和服务。请您务必审慎阅读、充分理解本协议各条款，特别是免除或者限制有关责任的条款，并选择接受或不接受。限制、免责条款可能以黑体加粗形式提示您注意。除非您阅读并接受本协议所有条款，否则请您不要下裁、安装或使用本软件及相关服务。您的点击同意、下裁、安装、使用、登录、等行为即视为您已阅读并同意本协议的约束。',
  protocol2:
    '您在法律上同意这些条款是具有约束力的规则（“条款”）。您还同意我们的隐私政策，Cookie政策，付款验证政策，并同意您有责任遵守任何应适用的当地法律，我们的隐私政策、支付政策以及其他政策构成本协议的一部分。我们可能会不时更改这些条款。如果这样做，我们会通过在网站上公布或向您发送电子邮件来通知您任何重大更改。这些条款的新版本不会追溯适用，我们会告诉您新版本条款确切的生效日期，并于发布时列于本协议首部。如果更改后仍继续使用%channelName%APP软件及服务，则表示您接受新条款。我们保留本协议未明确授予的任何和所有权利，包括但不限于系统的任何和所有权利。',
  protocol3:
    '违反本协议任何条款将导致您的帐户被终止。您同意自行承担使用%channelName%APP软件及服务的风险。',
  protocol4:
    '用户协议欢迎您使用我们（即%channelName%APP服务提供者，简称“我们”）的产品和服务，本服务协议适用于%channelName%APP服务提供者以网站、软件客户端以及随技术发展出现的新形态向您提供的各项产品和服务。请您务必审慎阅读、充分理解本协议各条款，特别是免除或者限制有关责任的条款，并选择接受或不接受。限制、免责条款可能以黑体加粗形式提示您注意。除非您阅读并接受本协议所有条款，否则请您不要下裁、安装或使用本软件及相关服务。您的点击同意、下裁、安装、使用、登录、等行为即视为您已阅读并同意本协议的约束。您在法律上同意这些条款是具有约束力的规则（“条款”）。您还同意我们的隐私政策，Cookie政策，付款验证政策，并同意您有责任遵守任何应适用的当地法律，我们的隐私政策、支付政策以及其他政策构成本协议的一部分。我们可能会不时更改这些条款。如果这样做，我们会通过在网站上公布或向您发送电子邮件来通知您任何重大更改。这些条款的新版本不会追溯适用，我们会告诉您新版本条款确切的生效日期，并于发布时列于本协议首部。如果更改后仍继续使用%channelName%APP软件及服务，则表示您接受新条款。我们保留本协议未明确授予的任何和所有权利，包括但不限于系统的任何和所有权利。违反本协议任何条款将导致您的帐户被终止。您同意自行承担使用%channelName%APP软件及服务的风险。如您在签署和接受本协议之前或之后与我们签署了与本服务有关的协议（以下简称“线下协议”）且该线下协议仍然有效的，如线下协议与本协议约定有冲突之处，以线下协议的约定为准；如线下协议未涉及的内容，以本协议的约定为准。：',
  protocol5: '1.账户条款：',
  protocol6:
    '您必须年满18岁才能注册%channelName%APP账户，或您的年龄必须足以在您居住的地方签',
  protocol7:
    '订有约束力的合同。如果您未满18岁，则未经父母或监护人的同意（代表您同意您将遵守服务条款），您不能使用%channelName%APP产品。',
  protocol8:
    '您需要注册或与官方客服联系申请方可使用%channelName%APP，选择帐户名称并设置密码。您的信息将根据可适用的数据保护法以及我们的隐私政策进行收集和披露。所有用户在注册我们的网站或服务时都必须提供真实和准确的信息，请勿使用无效或他人的电子邮件。',
  protocol9:
    '我们保留验证所有用户信息并拒绝任何用户的权利。您应对帐户中的所有活动负责，并对密码保密。您同意将任何未经授权使用您的帐户或任何其他违反安全性的行为立即通知我们。对于他人在您知情或不知情的情况下使用您的密码或帐户给您造成的任何损失，%channelName%APP不承担任何责任。如果发现有人未经您的许可使用了您的帐户，请联系telegram客服',
  protocol10:
    '您不得将%channelName%APP软件及服务用于任何非法或未经授权的目的。您在使用软件时，不得违反您所在司法管辖区的任何法律（包括但不限于版权法）及其他任何应适用的法律规定。',
  protocol11:
    '您同意%channelName%APP可以在提供服务的过程中自行或由第三方广告商通过短信、电子邮件或电子信息等多种方式向您发送广告、推广或宣传信息（包括商业与非商业信息）。您同意，对%channelName%APP服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。',
  protocol12: '2.禁止行为',
  protocol13:
    '您应当遵守任何应适用的法律法规的规定，不得利用%channelName%APP的服务及账户实施包括但不限于以下行为，也不得为以下行为提供便利：',
  protocol14:
    '（1）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一、损害国家荣誉和利益的；',
  protocol15: '（2）宣扬邪教和封建迷信的；',
  protocol16: '（3）散布谣言，扰乱社会秩序，破坏社会稳定的；',
  protocol17:
    '（4）散布淫秽、色情、赌博、暴力、凶杀、恐怖、骚扰、低俗或者教唆犯罪的；',
  protocol18: '（5）侮辱或者诽谤他人，侵害他人合法权益的；',
  protocol19: '（6）侵害他人知识产权、商业秘密等合法权利的；',
  protocol20: '（7）利用本软件虚构事实、隐瞒真相以误导、欺骗他人的；',
  protocol21: '（8）实施博彩、赌博游戏等非法互联网活动的；',
  protocol22: '（9）删除或者改变本软件上的所有权利管理电子信息；',
  protocol23:
    '（10）故意避开或者破坏著作权人为保护本软件著作权而采取的技术措施；',
  protocol24:
    '（11）违反国家规定，对计算机信息系统功能进行删除、修改、增加、干扰，造成计算机信息系统不能正常运行；',
  protocol25: '（12）未经允许，进入计算机信息网络或者使用计算机信息网络资源；',
  protocol26: '（13）未经允许，对计算机信息网络功能进行删除、修改或者增加的；',
  protocol27:
    '（14）未经允许，对计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；',
  protocol28:
    '（15）破坏本软件系统或网站的正常运行，故意传播计算机病毒等破坏性程序；',
  protocol29:
    '（16）以任何方式进行%channelName%APP产品或乙方授权的管理用户下的邀请码倒卖活动；',
  protocol30: '（17）其他任何危害计算机信息网络安全的；',
  protocol31: '（18）其他违反公序良俗或法律法规禁止的行为。',
  protocol32:
    '您承诺，您不会使用任何类型的软件或设备（无论是手动还是自动的）来“爬行”或“蜘蛛化”%channelName%APP的任何部分，也不会对%channelName%APP的任何内容进行拆解或反向工程。',
  protocol33:
    '您理解并同意，若您实施了本协议的任何禁止行为，或将%channelName%APP软件及服务用于任何非法或未经授权的目的，或有其他违反本协议的行为，%channelName%APP有权依合理判断对',
  protocol34:
    '违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。',
  protocol35:
    '您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；%channelName%APP因此遭受损失的，您也应当一并赔偿。若%channelName%APP因生效判决、裁决等先行承担了相关责任的，%channelName%APP有权向您全额追偿。',
  protocol36: '3.费用条款',
  protocol37:
    '请访问我们的Telegram客服，了解我们的所有服务的价格和最新优惠。%channelName%APP的某些功能是免费的，某些功能只能付费使用。如果您同意我们的付款政策并订购相关功能，则您承诺支付相关功能的费用。所有服务的价格（包括但不限于软件的每月订购计划费用）可能会发生变化。',
  protocol37_1:
    '独角兽产品一经支付，账户开启，则视为服务已完成，后续概不退款。',
  protocol38:
    '我们保留随时或不定期更改或终止本服务（或其任何部分）的权利。%channelName%APP无须就对本服务的任何修改、价格变动、暂停或终止对您或任何第三方承担任何责任。',
  protocol39: '4.协议终止',
  protocol40:
    '本协议将自您接受本使用条款之日起生效，并且有效期为长期，直到任何一方将其终止。%channelName%APP有权随时出于任何原因暂停或终止您的帐户或服务，可能导致您的帐户被停用或删除，或您对帐户的访问被限制，以及帐户中所有内容被删除或清除，%channelName%APP将不承担任何赔偿责任。%channelName%APP保留随时出于任何原因拒绝向任何人提供服务的权利。',
  protocol41:
    '您可以随时通过官网客服或通过Telegram与我们联系，请注意，终止帐户后，可能会立即禁止访问我们网站或服务的某些部分。如有以下情况，我们有权与您终止本协议：',
  protocol42: '您在使用我们的网站或服务时违反了任何适用法律；',
  protocol43: '您违反本协议或网站或服务的条款；',
  protocol44: '我们认为您的任何行为在法律上损害我们的商业利益。',
  protocol45: '5.知识产权',
  protocol46:
    '%channelName%APP网站或产品中的所有的内容、设计、文本、图表、图像、标识、按钮、图标、界面、音频及视频以及以上内容的所有排列组合均为%channelName%APP或其相应内容提供商的专属财产，受可适用的著作权法保护。网站所使用的所有软件为%channelName%APP或其相应的软件供应商的财产，受可适用的著作权法保护和其他相关法律和条约保护。网站用户不得发送/上传任何侵犯他人著作权或其他知识产权的内容至本网站。',
  protocol47:
    '%channelName%APP网站或产品使用的所有商标、服务标识和商业名称（统称“标识”）均为%channelName%APP独有。该等标识不得用于任何非%channelName%APP的产品或服务，或任何导致消费者混淆、或以任何方式诋毁或败坏%channelName%APP名声的其他产品或服务上。',
  protocol48: '6.保密和反馈',
  protocol49:
    '我们重视您的隐私，我们的隐私权政策已纳入本协议，请查看我们的隐私政策，以便您了解我们的隐私惯例。我们收集的所有信息均受我们的隐私政策的约束，通过使用网站或服务，您同意我们根据隐私政策对您的信息采取的所有措施。',
  protocol50:
    '您同意对机密信息保密，不得将其用于履行本协议以外的目的，也不得向第三方披露您知道的%channelName%APP机密信息。您向我们提供的有关任何%channelName%APP软件或服务的任何建议、信息、想法或反馈，包括但不限于您在使用软件或相关服务时发现的任何错误的报告文档（统称“反馈”），均将归公司所有。您同意在全球范围内将“反馈”中的所有权利，包括但不限于所有权和利益以及相关的知识产权无偿转让给%channelName%APP，并同意协助我们完善和行使这些权利。',
  protocol51: '7.免责声明',
  protocol52:
    '我们建议您经常备份数据。您应始终有责任减轻损失。此软件不是容错软件，也不是设计用于或旨在用于任何需要保证安全性能或操作的危险环境中。%channelName%APP不保证包含服务的网站或产品将是无错误的或无中断的，也不承诺该网站或服务或服务器不受任何有害组件的影响。%channelName%APP不对提供的服务是准确，完整或有用的作任何陈述或保证。%channelName%APP并不保证您在任何特定的司法管辖区均合法使用该网站或服务，并且我们特别声明不作任何此类保证。',
  protocol53:
    '我们不会介入用户之间或用户与任何第三方之间有关使用服务的纠纷。当您使用服务时，您承诺%channelName%APP将免遭于与此类纠纷和服务有关的任何索赔和要求（不论已知或未知，可疑或意外，公开或未公开）。您将自行承担因使用%channelName%APP网站或服务而招致的任何结果，您对因您行为而对任何一方造成的任何损害或损失负全责。',
  protocol54:
    '任何使用、测试或评估%channelName%APP的用户都应对%channelName%APP的质量和性能自行承担全部风险。我们对任何形式的损害概不负责，包括但不限于因使用、执行或交付%channelName%APP而造成的直接或间接损害，即使%channelName%APP已被告知存在或可能造成这种损害。',
  protocol55:
    '某些国家（地区）不允许限制或排除偶发或继发性损害的责任，因此上述限制或排除可能对您不适用。在任何情况下，我们的责任均不会超过您为%channelName%APP支付的购买价格。无论您是否接受使用、评估或测试%channelName%APP，上述免责声明和限制均适用。',
  protocol56:
    '欢迎使用%channelName%SCRM！我们深知您对个人信息和隐私的重视，因此我们制定了本隐私权政策，以阐明我们如何收集、使用、存储和保护您的个人信息。在使用%channelName%SCRM服务之前，请您仔细阅读并充分理解本政策。',
  protocol57: '一、信息收集',
  protocol58:
    '1.用户注册信息：当您注册%channelName%SCRM账户时，我们将收集您的用户名、密码、邮箱地址等基本信息，以便为您提供服务。',
  protocol59:
    '2.通信数据：为了实现跨语言沟通，我们将收集您在使用%channelName%SCRM时的聊天记录、发送的文件和图片等信息。我们会对这些数据进行加密处理，确保您的隐私得到保护。',
  protocol60:
    '3.设备信息和日志数据：为了提供更优质的服务，我们将收集您的设备型号、操作系统版本、IP地址、浏览器类型等信息，以及您在使用%channelName%SCRM过程中产生的日志数据。',
  protocol61: '二、信息',
  protocol62:
    '1.为您提供服务：我们将使用您的信息来提供、维护和优化%channelName%SCRM服务。',
  protocol63:
    '2.产品改进：我们会分析用户行为和反馈，以了解用户需求并优化我们的产品和服务。',
  protocol64:
    '3.客户支持：我们会使用您提供的信息，以协助您解决使用过程中遇到的问题。',
  protocol65:
    '4.市场推广：在征得您同意的情况下，我们可能会使用您的联系信息向您发送产品更新、促销活动等信息。您可以随时选择退订此类通知。',
  protocol66:
    '5.法律合规：我们可能需要使用您的信息，以满足法律、法规和政府要求，或保护我们的合法权益。',
  protocol67: '三、信息存储与访问',
  protocol68:
    '1.数据存储：我们将在合规的数据中心存储您的个人信息，并采取合理措施保护数据安全。',
  protocol69:
    '2.数据加密：我们使用先进的加密技术（如AES-256）对您的聊天记录进行加密，确保即使数据泄露，也无法轻易破解和阅读。',
  protocol70:
    '3.访问控制：我们对内部员工进行严格的权限管理，只有授权人员才能访问用户数据。',
  protocol71:
    '4.安全审计：我们定期审查和升级安全系统，以确保数据安全始终处于最佳状态。',
  protocol72: '四、信息共享及隐私保护',
  protocol73:
    '我们承诺不会将您的个人信息出售给任何第三方。除非符合以下情况，否则我们不会与任何第三方共享您的信息：',
  protocol74:
    '1.获得您的同意：在征得您明确同意的情况下，我们可能会与第三方共享您的信息。',
  protocol75:
    '2.法律要求：如法律、法规或政府部门要求，我们可能需要与相关机构共享您的信息。',
  protocol76:
    '3.合作伙伴：我们可能与合作伙伴共享您的信息，以提供某些功能或服务。我们将确保这些合作伙伴同样遵守本隐私权政策。',
  protocol77:
    '4.业务转让：如发生公司重组、合并或出售等情况，我们可能需要将您的信息转移给相关参与方。在此情况下，我们将确保新的所有方继续遵循本隐私权政策。',
  protocol78: '五、用户',
  protocol79:
    '1.访问、更正和删除：您有权访问、更正和删除您的个人信息。如需执行这些权利，请联系我们的客户支持团队。',
  protocol80:
    '2.数据查看：您有权查看您的聊天记录和其他个人资料。如需查看您的聊天记录和资料，您可以通过我们提供的界面自行查看并复制。',
  protocol81: '3.退订：您有权随时退订我们发送的营销信息。',
  protocol82: '六、未成年人',
  protocol83:
    '我们的服务并非针对未满18周岁的未成年人。如您是未成年人，请在法定监护人的陪同下使用我们的服务。如我们发现收集了未成年人的个人信息，我们将立即删除相关数据。',
  protocol84: '七、政策',
  protocol85:
    '我们可能会不时更新本隐私权政策。如政策发生重大变更，我们将通过网站公告、电子邮件等方式通知您。请您定期查看本政策以了解最新信息。',
  protocol86: '八、联系',
  protocol87:
    '如您对本隐私权政策有任何疑问或建议，请联系我们的客户支持团队。我们将竭诚为您解答疑问，解决问题。',
  protocol88:
    '感谢您选择%channelName%SCRM，我们期待为您提供优质的跨语言沟通服务！',
  protocol89:'查看更多',
  protocol90:'隐私协议',
};
